import * as React from "react";
import { Link } from "@fitplan/lib/components/Link";
import { graphql } from "gatsby";

import PageWrapper from "../components/pageWrapper/PageWrapper";
import localize from "../hoc/Localize";

import "./download.scss";

export interface Props {
    data: {
        sanitySelectDevice: {
            title: string;
            apple: string;
            google: string;
        };
    };
}

const DownloadPage: React.FunctionComponent<Props> = props => {
    const device = props.data.sanitySelectDevice;
    return (
        <PageWrapper {...props} className="download__layout">
            <div
                className="download__container"
                data-testid="download__container"
            >
                <div className="download__info" data-testid="download__info">
                    <div
                        className="download__title"
                        data-testid="download__title"
                    >
                        {device.title}
                    </div>

                    <div
                        className="download__mobile"
                        data-testid="download__mobile"
                    >
                        <div
                            className="download__column"
                            data-testid="download__column"
                        >
                            <Link to={device.apple}>
                                <img
                                    className="download__icon"
                                    data-testid="download__icon"
                                    src="/images/select-device/apple-badge.png"
                                />
                            </Link>
                        </div>

                        <div
                            className="download__column"
                            data-testid="download__column"
                        >
                            <Link to={device.google}>
                                <img
                                    className="download__icon"
                                    data-testid="download__icon"
                                    src="/images/select-device/google-available.png"
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </PageWrapper>
    );
};

export const query = graphql`
    query selectDeviceQuery {
        sanitySelectDevice {
            title {
                _type
                en
                es
            }
            apple
            google
        }
    }
`;

export default localize(DownloadPage);
